
import React from 'react';
import { api, CopyRightFooter } from '../../utils';
import { LoginForm } from '../loginForm/login';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

export default class CloudWmsLogin extends React.Component<{ store?; }> {
  render(): JSX.Element {
    return (
      <div className={styles.login}>
        <div className={styles.background}>
          <img
            className={styles.backgroundImg}
            src={`${api.oss}/images/cloudWmsBg.png`}
          />
        </div>
        <div className={styles.header}>
          <img
            className={styles.logo}
            src={`${api.oss}/images/shanshuLogo.png`}
          />
          <div>
            <img
              className={styles.title}
              src={`${api.oss}/images/cloudWmsTitle.png`}
            />
          </div>
          <ul className={styles.description}>
            <li>
              百万级系统支撑，智能化仓储服务
            </li>
            <li>
              弹性仓储面积，有效降低仓库成本
            </li>
            <li>
              专业化网仓管理
            </li>
            <li>
              定制化仓储服务
            </li>
            <li>
              档口协同备货，提升发货效率
            </li>
            <div>
              …
            </div>
          </ul>
        </div>
        <div className={styles.content}>
          <LoginForm
            accountType={3}
            changePasswordPath="/egenie-ts-iac/findPassword"
            loginPath="/egenie-ts-iac/cloudWmsLogin"
            sysType="pc_cloudwms"
            tenantModule="100012"
          />
        </div>
        <CopyRightFooter/>
      </div>
    );
  }
}
